import React from 'react'

interface IProps {
    className?: string
    onClick?: () => void
    checked?: boolean
    disabled?: boolean
}

const WizRadioButton: React.FC<IProps> = ({className, onClick, checked, disabled}) => {
    return (
        <div
            className={`w-[24px] h-[24px] rounded-full flex justify-center items-center ${
                disabled
                    ? 'border border-gray06 dark:border-dark_gray06 bg-gray07 dark:bg-dark_gray07'
                    : checked
                    ? 'bg-primary dark:bg-dark_primary'
                    : 'border border-gray05 dark:border-dark_gray05 bg-white dark:bg-bg_dark_background'
            } ${className}`}
            onClick={() => {
                if (!disabled && onClick) {
                    onClick()
                }
            }}>
            {(disabled || checked) && (
                <div
                    className={`w-[10px] h-[10px] ${
                        disabled ? 'bg-gray05 dark:bg-dark_gray05' : 'bg-white'
                    } rounded-full`}></div>
            )}
        </div>
    )
}

export default WizRadioButton
