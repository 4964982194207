import React from 'react'
import WizImage from '@component/images/WizImage'
import ic_exchange from '@image/ic_exchange.png'

interface IProps {
    src: any
    imageClassName?: string
    containerClassName?: string
    size?: number
    unoptimized?: boolean
}

const ExchangeIcon: React.FC<IProps> = ({src, imageClassName, containerClassName, size, unoptimized}) => {
    return (
        <WizImage
            src={src}
            containerClassName={`${containerClassName} flex-none`}
            imageClassName={`rounded-full ${imageClassName}`}
            width={size}
            height={size}
            placeHolder={ic_exchange}
            unoptimized={unoptimized}
        />
    )
}

export default ExchangeIcon
