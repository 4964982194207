import React, {useMemo} from 'react'
import WizImage from '@component/images/WizImage'
import ic_token_placeholder from '@image/ic_token_placeholder.png'

import LeagueCircleIcon from '@image/lot_circle_logo.png'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'

interface IProps {
    src?: string
    exchange?: string
    imageClassName?: string
    containerClassName?: string
    size?: number
    unoptimized?: boolean
}

const LeagueIcon: React.FC<IProps> = ({src, exchange, imageClassName, containerClassName, size, unoptimized}) => {
    const {getExchange} = useQueryGetExchanges()

    const imageIcon = useMemo(() => {
        if (src) {
            return src
        } else if (exchange) {
            return getExchange(exchange)?.icon
        } else {
            return LeagueCircleIcon
        }
    }, [src, exchange, getExchange])

    return (
        <WizImage
            src={imageIcon}
            containerClassName={`${containerClassName} flex-none`}
            imageClassName={` ${imageClassName}`}
            width={size}
            height={size}
            placeHolder={ic_token_placeholder}
            unoptimized={unoptimized}
        />
    )
}

export default LeagueIcon
