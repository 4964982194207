import React from 'react'

interface IProps {
    className?: string
}

const VerticalDivider: React.FC<IProps> = ({className}) => {
    return <div className={`w-[1px] h-full ${className}`} />
}

export default VerticalDivider
