import React, {MutableRefObject, useEffect, useImperativeHandle, useRef, useState} from 'react'
import WizBottomSheet from '@component/bottomsheet/WizBottomSheet'

const BottomSheetLoader = () => {
    const [props, setProps] = useState<BottomSheetProps | null>(null)
    const [isOpened, setIsOpened] = useState(false)

    const bottomSheetRef = useRef<BottomSheetRef>(null)

    useEffect(() => {
        BottomSheet.setRef(bottomSheetRef)
    }, [])

    useImperativeHandle(
        bottomSheetRef,
        () => ({
            show: (props: BottomSheetProps) => {
                setProps(props)
                showBottomSheet()
            },
            hide: () => {
                setIsOpened(false)
                props?.onDismiss?.()
                setProps(null)
            },
        }),
        [props],
    )

    // useEffect(() => {
    //     let interval: NodeJS.Timeout
    //     if (!props) {
    //         closeBottomSheet()
    //         return
    //     }
    //
    //     interval = setTimeout(() => {
    //         showBottomSheet()
    //     }, 500)
    //
    //     if (props?.hideDuration) {
    //         interval = setTimeout(() => {
    //             closeBottomSheet()
    //         }, props?.hideDuration)
    //     }
    //     return () => clearInterval(interval)
    // }, [props])

    const showBottomSheet = () => {
        setIsOpened(true)
    }

    return <WizBottomSheet {...props} isOpened={isOpened} />
}
export default React.forwardRef(BottomSheetLoader)

export type BottomSheetProps = {
    className?: string
    children?: React.ReactNode
    onDismiss?: () => void
    hideDuration?: number
}

export type BottomSheetRef = {
    show: (props: BottomSheetProps) => void
    hide: () => void
}

export class BottomSheet {
    static bottomSheetRef: MutableRefObject<BottomSheetRef>
    static setRef = (ref: any) => {
        this.bottomSheetRef = ref
    }
    static show = (props: BottomSheetProps) => {
        this.bottomSheetRef?.current?.show(props)
    }
    static hide = () => {
        this.bottomSheetRef?.current?.hide()
    }
}
