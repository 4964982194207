import React from 'react'
import Text from '@component/Text/Text'

interface IProps {
    text: string
    onClick?: () => void
    disabled?: boolean
    showArrow?: boolean
    className?: string
}

const WizTextButton: React.FC<IProps> = ({text, onClick, disabled = false, showArrow = false, className}) => {
    const renderRightIcon = () => {
        if (showArrow) {
            return renderArrow()
        } else {
            return null
        }
    }

    const borderClassName = () => {
        if (disabled) return 'border-b border-gray04 dark:border-dark_gray04'
        else return 'border-b border-b-transparent hover:border-gray02 dark:hover:border-dark_gray02'
    }

    const textStyleClassName = () => {
        if (disabled) return 'text-gray04 dark:text-dark_gray04'
        else return 'text-gray02 dark:text-dark_gray02'
    }

    const hoverBackgroundClassName = () => {
        if (disabled) return ''
        else return 'hover:bg-primary_tint01 dark:hover:bg-dark_primary_tint01'
    }

    const renderArrow = () => {
        let fillClassName = disabled ? 'fill-gray04 dark:fill-dark_gray04' : 'fill-gray02 dark:fill-dark_gray02'
        return (
            <svg width="12" height="12" viewBox="0 0 12 12" className={`${fillClassName}`}>
                <path d="M6.35355 1.64645C6.15829 1.45118 5.84171 1.45118 5.64645 1.64645C5.45118 1.84171 5.45118 2.15829 5.64645 2.35355L6.35355 1.64645ZM10 6L10.3536 6.35355C10.5488 6.15829 10.5488 5.84171 10.3536 5.64645L10 6ZM5.64645 9.64645C5.45118 9.84171 5.45118 10.1583 5.64645 10.3536C5.84171 10.5488 6.15829 10.5488 6.35355 10.3536L5.64645 9.64645ZM5.64645 2.35355L9.64645 6.35355L10.3536 5.64645L6.35355 1.64645L5.64645 2.35355ZM9.64645 5.64645L5.64645 9.64645L6.35355 10.3536L10.3536 6.35355L9.64645 5.64645Z" />
            </svg>
        )
    }
    return (
        <button
            className={`flex items-center ${borderClassName()} ${textStyleClassName()} ${hoverBackgroundClassName()} ${className}`}
            disabled={disabled}
            onClick={onClick}>
            <Text className="text-body2">{text}</Text>
            {renderRightIcon()}
        </button>
    )
}

export default WizTextButton
