import React, {useMemo} from 'react'
import {Menu} from '@headlessui/react'
import Text from '@component/Text/Text'

interface IProps {
    optionList: Array<{title: string; onClick: any}>
    className?: string
    renderView?: any
    downViewAlign?: 'start' | 'left' | 'center' | 'right' | 'end'

    containerClassName?: string
}

const WizDropdown: React.FC<IProps> = ({
    className,
    optionList,
    renderView,
    downViewAlign = 'center',
    containerClassName,
}) => {
    const styleDownView = useMemo(() => {
        switch (downViewAlign) {
            case 'start':
                return 'left-0'
            case 'left':
                return ''
            case 'center':
                return 'left-[50%] -translate-x-1/2'
            case 'right':
                return '-translate-x-1/2'
            case 'end':
                return 'right-0'
        }
    }, [downViewAlign])

    return (
        <div className={`relative ${className} z-dropdown`}>
            <Menu>
                {({open}) => (
                    <>
                        <Menu.Button className={`flex items-center  ${containerClassName}`}>{renderView}</Menu.Button>
                        {open && (
                            <Menu.Items
                                modal={false}
                                className={`absolute w-40 transform ${styleDownView} top-full mt-2 bg-white dark:bg-bg_dark_popup border border-gray06 dark:border-dark_gray06 shadow-normal rounded-md`}>
                                {optionList.map(option => {
                                    return (
                                        <Menu.Item key={option.title}>
                                            <div
                                                className={`py-3 px-4 cursor-pointer hover:bg-gray07 dark:hover:bg-dark_gray07`}
                                                onClick={async () => {
                                                    option.onClick()
                                                }}>
                                                <Text className={'text-body2 text-gray01 dark:text-dark_gray01'}>
                                                    {option.title}
                                                </Text>
                                            </div>
                                        </Menu.Item>
                                    )
                                })}
                            </Menu.Items>
                        )}
                    </>
                )}
            </Menu>
        </div>
    )
}

export default WizDropdown
