import React from 'react'

interface IProps {
    className?: string
    selectedDotClassName?: string
    unSelectedDotClassName?: string
    totalSize?: number
    selectedIndex: number
}

const WizDotIndicator: React.FC<IProps> = ({
    className,
    selectedDotClassName,
    unSelectedDotClassName,
    totalSize,
    selectedIndex = 0,
}) => {
    return (
        <div className={`${className}`}>
            {!isNaN(totalSize) &&
                totalSize &&
                [...new Array(totalSize)]?.map((_, i) => {
                    return (
                        <div
                            key={i.toString()}
                            className={`h-[5px] rounded-[5px] ${
                                selectedIndex === i
                                    ? `${selectedDotClassName} w-[19px]`
                                    : `${unSelectedDotClassName} w-[5px]`
                            } transition-all ease-in-out duration-300`}
                        />
                    )
                })}
        </div>
    )
}
export default WizDotIndicator
