import React from 'react'
import WizImage from '@component/images/WizImage'
import ic_token_placeholder from '@image/ic_token_placeholder.png'

interface IProps {
    src: any
    imageClassName?: string
    containerClassName?: string
    size?: number
    unoptimized?: boolean
}

const TokenLogo: React.FC<IProps> = ({src, imageClassName, containerClassName, size, unoptimized}) => {
    return (
        <WizImage
            src={src}
            containerClassName={containerClassName}
            imageClassName={`rounded-full ${imageClassName}`}
            width={size}
            height={size}
            isResize={false}
            placeHolder={ic_token_placeholder}
            unoptimized={unoptimized}
        />
    )
}

export default React.memo(TokenLogo)
