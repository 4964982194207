import React from 'react'
import WizPortfolioAreaChart, {
    IPortfolioColorPalette,
    PortfolioAreaChartType,
} from '@component/charts/WizPortfolioAreaChart/WizPortfolioAreaChart'

interface Props {
    width: number
    height: number
    data: {x: number; y: number}[]
    chartType?: PortfolioAreaChartType
    colorPalette?: {line?: IPortfolioColorPalette; gradient?: IPortfolioColorPalette}
    borderWidth?: number
    className?: string
}

const WizSimpleAreaChart = ({width, height, data, chartType, colorPalette, borderWidth, className}: Props) => {
    if (!data || data.length === 0) return null

    const last = data[data.length - 1].y

    return (
        <WizPortfolioAreaChart
            chartData={data}
            chartType={chartType ?? 'profit'}
            isSimple={true}
            borderWidth={borderWidth ?? 1.5}
            chartPeriod={'1000'}
            width={width}
            height={height}
            colorPalette={colorPalette}
            className={className}
        />
    )
}

export default React.memo(WizSimpleAreaChart)
