import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import {Area, AreaChart, CartesianGrid, Label, ReferenceDot, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import nextId from 'react-id-generator'
import {getNumberColorClass} from '@util/etc'
import {isEmptyArray} from '@util/array'
import {useCurrencyStore} from '@store/CurrencyStore'
import {shallow} from 'zustand/shallow'
import useWindowSize from '@hook/useWindowSize'
import useDark from '@hook/useDark'

export type AreaChartPeriod = '7' | '30' | '90' | '180' | '365' | '1000'
export type AreaChartType = 'balance' | 'earning' | 'profit' | 'adjusted_profit' | 'aPnl'
export type AreaChartData = {x: number; y: number}

interface IProps {
    height?: number
    data: AreaChartData[]
    className?: string
    chartType: AreaChartType
    chartPeriod?: AreaChartPeriod
    hideTooltip?: boolean
    hideXAxis?: boolean
    hideYAxis?: boolean
    margin?: object
    refetchProps?: any
}

export const CHART_COLORS = {
    plus: '#0AAF82',
    minus: '#E6434E',
    neutral: '#89C7F9',
    leader: '#A89160',
    dark_plus: '#27C89B',
    dark_minus: '#E34E4E',
    dark_leader: '#C7AB6F',
}

const WizAreaChart = ({
    data,
    height,
    className,
    chartType,
    chartPeriod,
    hideTooltip = false,
    hideXAxis = false,
    hideYAxis = false,
    margin,
    refetchProps,
}: IProps) => {
    const {t} = useTranslation()
    const {displayDate} = useFormatDate()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const [minValueDate, setMinValueDate] = useState(0)
    const [maxValueDate, setMaxValueDate] = useState(0)
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)
    const [prevChart, setPrevChart] = useState<AreaChartData[]>([])
    const {isRenderDark} = useDark()
    const {selectedSymbol} = useCurrencyStore(
        state => ({
            selectedSymbol: state.selectedSymbol,
        }),
        shallow,
    )

    const {isSm} = useWindowSize()

    const shouldWriteYears = useMemo((): boolean => {
        if (isEmptyArray(data)) return false
        const firstTimeStamp = data[0]?.x
        const lastTimeStamp = data[data.length - 1]?.x

        if (firstTimeStamp === undefined || lastTimeStamp === undefined) return false

        const firstDataYear = new Date(firstTimeStamp).getFullYear()
        const LastDataYear = new Date(lastTimeStamp).getFullYear()
        const currentYear = new Date().getFullYear()

        return firstDataYear !== LastDataYear || LastDataYear !== currentYear
    }, [data])

    const stopColor = useMemo(() => {
        if (!data || data?.length === 0 || chartType === 'balance') {
            if (chartType === 'balance') {
                return CHART_COLORS.neutral
            } else {
                return isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
            }
        }
        const lastElement = data?.at(data?.length - 1)
        if (lastElement?.y > 0) return isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
        if (lastElement?.y < 0) return isRenderDark ? CHART_COLORS.dark_minus : CHART_COLORS.minus
        return isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
    }, [data, chartType, isRenderDark])

    const last = data?.at(data?.length - 1)?.y || 1
    const hash = nextId()

    const CustomTooltip = ({active, payload, label}: any) => {
        const {displayDate} = useFormatDate()
        const {displayPercent} = useFormatNumber()
        const {t} = useTranslation()

        if (active && payload && payload.length) {
            const x = payload[0].payload.x
            const y = payload[0].payload.y

            if (chartType === 'balance') {
                return (
                    <div className="px-6 py-3 bg-white bg-opacity-80 rounded-lg shadow-xl">
                        <p className={'font-sans text-body_comm text-gray03 dark:text-dark_gray03'}>
                            {displayDate(x / 1000, t('dateFormat.date', {defaultValue: 'MMM d, yyyy'}))}
                        </p>
                        <p className={`font-sans text-h3 text-gray01 dark:text-dark_gray01`}>
                            {displayBalance(y, {showPreSign: true})}
                        </p>
                    </div>
                )
            } else if (chartType === 'earning') {
                return (
                    <div className="px-6 py-3 bg-white bg-opacity-80 rounded-lg shadow-xl">
                        <p className={'font-sans text-body_comm text-gray03 dark:text-dark_gray03'}>
                            {displayDate(x / 1000, t('dateFormat.date', {defaultValue: 'MMM d, yyyy'}))}
                        </p>
                        <p className={`font-sans text-h3 ${getNumberColorClass(y)}`}>
                            {displayBalance(y, {showPreSign: true})}
                        </p>
                    </div>
                )
            } else if (chartType === 'aPnl') {
                return (
                    <div className="px-6 py-3 bg-white bg-opacity-80 rounded-lg shadow-xl">
                        <p className={'font-sans text-body_comm text-gray03 dark:text-dark_gray03'}>
                            {displayDate(x / 1000, t('dateFormat.date', {defaultValue: 'MMM d, yyyy'}))}
                        </p>
                        <p className={`font-sans text-h3 ${getNumberColorClass(y)}`}>{displayNumber(y, 1)} σ</p>
                    </div>
                )
            } else {
                return (
                    <div className="px-6 py-3 bg-white bg-opacity-80 rounded-lg shadow-xl">
                        <p className={'font-sans text-body_comm text-gray03 dark:text-dark_gray03'}>
                            {displayDate(x / 1000, t('dateFormat.date', {defaultValue: 'MMM d, yyyy'}))}
                        </p>
                        <p className={`font-sans text-h3 ${getNumberColorClass(y)}`}>{displayPercent(y)}</p>
                    </div>
                )
            }
        }
        return null
    }

    useEffect(() => {
        if (data?.length > 0) {
            const maxObj = data?.reduce((prev, value) => {
                return prev?.y >= value?.y ? prev : value
            })
            const minObj = data?.reduce((prev, value) => {
                return prev?.y <= value?.y ? prev : value
            })

            setMaxValueDate(maxObj?.x)
            setMinValueDate(minObj?.x)
            setMaxValue(maxObj?.y)
            setMinValue(minObj?.y)
            setMinValue(minObj?.y)
        }
    }, [data, refetchProps])

    const minLabelOffset = useMemo(() => {
        if (hideYAxis) return 'insideTop'

        const ArrCutLength = () => {
            if (data?.length >= 364) {
                return isSm ? Math.ceil(data?.length / 20) : Math.ceil(data?.length / 25)
            } else if (data?.length >= 180) {
                return isSm ? 15 : 10
            } else if (data?.length >= 89) {
                return isSm ? 8 : 5
            } else if (data?.length > 8) {
                if (chartType === 'earning' || chartType === 'balance') {
                    return isSm ? 4 : 3
                }
                return isSm ? 3 : 1
            } else {
                return 1
            }
        }

        if (data?.slice(ArrCutLength() * -1)?.find(item => item?.x === minValueDate)) {
            return 'insideTopRight'
        } else if (data?.slice(0, ArrCutLength())?.find(item => item?.x === minValueDate)) {
            return 'insideTopLeft'
        } else {
            return 'insideTop'
        }
    }, [data, minValueDate, chartType, hideYAxis, isSm])

    const maxLabelOffset = useMemo(() => {
        if (hideYAxis) return 'insideTop'
        const ArrCutLength = () => {
            if (data?.length >= 364) {
                return isSm ? Math.ceil(data?.length / 20) : Math.ceil(data?.length / 25)
            } else if (data?.length >= 180) {
                return isSm ? 15 : 10
            } else if (data?.length >= 89) {
                return isSm ? 8 : 5
            } else if (data?.length > 8) {
                if (chartType === 'earning' || chartType === 'balance') {
                    return isSm ? 4 : 3
                }
                return isSm ? 3 : 1
            } else {
                return 1
            }
        }

        if (data?.slice(ArrCutLength() * -1)?.find(item => item?.x === maxValueDate)) {
            return 'insideTopRight'
        } else if (data?.slice(0, ArrCutLength())?.find(item => item?.x === maxValueDate)) {
            return 'insideBottomLeft'
        } else {
            return 'insideTop'
        }
    }, [data, maxValueDate, chartType, hideYAxis, isSm])

    const calculateStepInterval = useMemo(() => {
        let dataDomain = maxValue - minValue
        const guideLineSpace = 49
        const guideLineCount = Math.floor(height / guideLineSpace)

        const rawInterval = dataDomain / (guideLineCount - 1)

        if (rawInterval === 0 && (chartType === 'profit' || chartType === 'adjusted_profit' || chartType === 'aPnl'))
            return 1

        const roundingFactor = Math.pow(10, Math.floor(Math.log10(rawInterval)))

        const normalizedInterval = rawInterval / roundingFactor

        let roundedInterval = 0

        if (normalizedInterval < 1.5) {
            roundedInterval = 1
        } else if (normalizedInterval < 3.5) {
            roundedInterval = 2
        } else if (normalizedInterval < 7.5) {
            roundedInterval = 5
        } else {
            roundedInterval = 10
        }

        roundedInterval *= roundingFactor

        return roundedInterval
    }, [minValue, maxValue, height, chartType])

    const CustomizedYAxisTick = ({x, y, stroke, payload}: any) => {
        const {displayPercent} = useFormatNumber()

        if (chartType === 'balance' || chartType === 'earning') {
            return (
                <svg y={1} textAnchor={'end'} dominantBaseline={'middle'} rx={10} className={'sm:hidden'}>
                    <text x={x} y={y} fill={'#8A8F95'} fontSize="10px">
                        {selectedSymbol === 'btc'
                            ? displayBalance(payload.value, {showPreSign: true})
                            : displayBalance(payload.value, {
                                  showPreSign: true,
                                  isDigit: calculateStepInterval <= 1 ? true : false,
                              })}
                    </text>
                </svg>
            )
        } else if (chartType === 'aPnl') {
            return (
                <svg y={1} textAnchor={'end'} dominantBaseline={'middle'} rx={10} className={'sm:hidden'}>
                    <text x={x} y={y} fill={'#8A8F95'} fontSize="10px">
                        {displayNumber(payload.value, 1)} σ
                    </text>
                </svg>
            )
        } else {
            return (
                <svg y={1} textAnchor={'end'} dominantBaseline={'middle'} rx={10} className={'sm:hidden'}>
                    <text x={x} y={y} fill={'#8A8F95'} fontSize="10px">
                        {displayPercent(payload.value, calculateStepInterval < 1 ? 2 : 0)}
                    </text>
                </svg>
            )
        }
    }

    const dataArr = useMemo(() => {
        if (data) {
            setPrevChart(data)
            return data
        } else {
            return prevChart?.length > 0 ? prevChart : []
        }
    }, [data, prevChart])

    const renderXAxisTicks = useMemo(() => {
        if (isSm) {
            return [data?.at(0)?.x, data?.at(Math.floor(data?.length * 0.5))?.x, data?.at(-1)?.x]
        }
        if (data?.length < 10) {
            return []
        }
        return [
            data?.at(0)?.x,
            data?.at(Math.floor(data?.length * 0.25))?.x,
            data?.at(Math.floor(data?.length * 0.5))?.x,
            data?.at(Math.floor(data?.length * 0.75))?.x,
            data?.at(-1)?.x,
        ]
    }, [data, isSm])

    const getGuideLines = useMemo((): number[] => {
        if (hideYAxis) return []
        let step = calculateStepInterval

        const startTick = Math.floor(minValue / step) * step
        const guidelines = []

        let currentTick = startTick

        while (currentTick <= maxValue + step) {
            guidelines?.push(currentTick)
            currentTick += step
        }

        return guidelines
    }, [minValue, maxValue, hideYAxis, calculateStepInterval])

    return (
        <div className={'relative'}>
            <ResponsiveContainer width={'99%'} height={height} className={`${className}`}>
                <AreaChart data={dataArr} margin={margin ?? {top: 0, left: 0, right: 0, bottom: 0}}>
                    <defs>
                        <linearGradient id={hash} x1="0" y1="0" x2="0" y2="1">
                            {last > 0 ? (
                                <>
                                    <stop offset="1%" stopColor={stopColor} stopOpacity={0.7} />
                                    <stop offset="99%" stopColor={stopColor} stopOpacity={0} />
                                </>
                            ) : (
                                <>
                                    <stop offset="5%" stopColor={stopColor} stopOpacity={0} />
                                    <stop offset="95%" stopColor={stopColor} stopOpacity={0.5} />
                                </>
                            )}
                        </linearGradient>
                    </defs>
                    <Area
                        type="monotone"
                        dataKey="y"
                        stroke={stopColor}
                        fillOpacity={0.5}
                        fill={`url(#${hash})`}
                        strokeWidth={2}
                        animationDuration={500}
                    />
                    {!hideTooltip && <Tooltip content={<CustomTooltip />} cursor={true} />}

                    {!hideXAxis && (
                        <XAxis
                            dataKey="x"
                            textAnchor={'start'}
                            type="number"
                            scale={'time'}
                            id={hash}
                            tickLine={false}
                            axisLine={false}
                            height={20}
                            interval={'preserveStartEnd'}
                            domain={['dataMin', 'dataMax']}
                            ticks={renderXAxisTicks}
                            tick={props => {
                                const isFirstChild = props?.index === 0
                                const isLastChild = props?.index === props?.ticks?.length - 1

                                // 첫 번째 tick과 마지막 tick에 대해 textAnchor를 변경
                                const textAnchor = isFirstChild
                                    ? isSm
                                        ? 'middle'
                                        : 'start'
                                    : isLastChild
                                    ? 'end'
                                    : 'middle'

                                return (
                                    <text
                                        x={props?.x}
                                        y={props?.y}
                                        fontSize={10}
                                        textAnchor={textAnchor}
                                        fill="#8A8F95">
                                        {displayDate(
                                            props?.payload?.value / 1000,
                                            t(shouldWriteYears ? 'dateFormat.date' : 'dateFormat.shortDate', {
                                                defaultValue: 'MMM d, yyyy',
                                            }),
                                        )}
                                    </text>
                                )
                            }}
                            tickMargin={23}
                            tickFormatter={x =>
                                displayDate(
                                    x / 1000,
                                    t(shouldWriteYears ? 'dateFormat.date' : 'dateFormat.shortDate', {
                                        defaultValue: 'MMM d, yyyy',
                                    }),
                                )
                            }
                        />
                    )}

                    {!hideYAxis && <CartesianGrid opacity={0.25} vertical={false} />}

                    {!hideYAxis && (
                        <YAxis
                            orientation="left"
                            tickLine={false}
                            axisLine={false}
                            width={0.5}
                            interval={0}
                            domain={['dataMin', 'dataMax']}
                            // ticks={renderYAxisTicks}
                            ticks={getGuideLines}
                            // tick={<></>}
                            tick={<CustomizedYAxisTick />}
                        />
                    )}

                    {!hideYAxis && dataArr?.length > 1 && (
                        <>
                            <ReferenceDot
                                x={maxValueDate}
                                y={maxValue}
                                r={3}
                                fill={'#242424'}
                                strokeWidth={0}
                                isFront={true}>
                                <Label
                                    className={'text-ti4 fill-gray01 dark:fill-dark_gray01 relative top-[20px]'}
                                    position={maxLabelOffset}
                                    offset={maxLabelOffset === 'insideBottomLeft' ? 10 : -10}
                                    value={`MAX ${
                                        chartType === 'balance' || chartType === 'earning'
                                            ? selectedSymbol === 'btc'
                                                ? displayBalance(maxValue, {showPreSign: true})
                                                : displayBalance(maxValue, {
                                                      showPreSign: true,
                                                  })
                                            : chartType === 'aPnl'
                                            ? `${displayNumber(maxValue, 1)} σ`
                                            : displayPercent(maxValue)
                                    }`}
                                />
                            </ReferenceDot>

                            <ReferenceDot
                                x={minValueDate}
                                y={minValue}
                                r={3}
                                fill={'#242424'}
                                strokeWidth={0}
                                isFront={true}>
                                <Label className={'w-[5px] h-[5px] rounded-full'}></Label>
                                <Label
                                    className={'text-ti4 fill-gray01 dark:fill-dark_gray01'}
                                    position={minLabelOffset}
                                    offset={10}
                                    value={`MIN ${
                                        chartType === 'balance' || chartType === 'earning'
                                            ? selectedSymbol === 'btc'
                                                ? displayBalance(minValue, {showPreSign: true})
                                                : displayBalance(minValue, {
                                                      showPreSign: true,
                                                  })
                                            : chartType === 'aPnl'
                                            ? `${displayNumber(minValue, 1)} σ`
                                            : displayPercent(minValue)
                                    }`}></Label>
                            </ReferenceDot>
                        </>
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default WizAreaChart
