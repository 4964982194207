import React from 'react'

interface IProps {
    className?: string
}

const HorizontalDivider: React.FC<IProps> = ({className}) => {
    return <div className={`h-[1px] w-full ${className}`} />
}

export default HorizontalDivider
